import React from "react"
import RouteFile from "./RouteFile"

const App = () => {
  return (
    <div>
      <RouteFile />
    </div>
  )
}

export default App
