import React from "react"

import "./AboutUs.css"
import Navbar from "../../Component/Navbar/Navbar"
import AboutUs from "../../Component/AboutUs/AboutUs"


const About = () => {
  return (
    <div>
  <Navbar/>
  <AboutUs/>
    </div>
  )
}

export default About
